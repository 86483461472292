.segmented-control {
  position: relative;
  display: inline-block;
  border: 1px solid currentColor;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  overflow: hidden;
  margin: 0.8em auto;
     -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
          border-radius: 20px;
     -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
     -moz-user-select: -moz-none;
      -ms-user-select:      none;
  -webkit-user-select:      none;
          user-select:      none;
     -moz-transition-duration: 0.8s;
       -o-transition-duration: 0.8s;
  -webkit-transition-duration: 0.8s;
          transition-duration: 0.8s;
     -moz-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
       -o-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
          transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-tap-highlight-color: transparent;
}
.segmented-control > input {
  position: absolute;
  left: -10000px;
  opacity: 0;
}
.segmented-control > input[type='checkbox'] ~ label {
     -moz-transition-duration: 0;
       -o-transition-duration: 0;
  -webkit-transition-duration: 0;
          transition-duration: 0;
}
.segmented-control > input[type='checkbox'] ~ label:before {
  opacity: 0;
}





/*
this section controls the division of the slider
if you have 2 options = 50%, 3 options = 33% and so on
but also the selected input matters. (input-1)*percentage
you'll also need to add a new entry for the new option
to do so just copy the last entry and change the numbers to [new highest]
and also make sure to set left: xx% correctly
*/
.segmented-control > input:disabled:nth-child(1) ~ label:nth-of-type(1) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(1):checked ~ label:nth-of-type(1):after,
.segmented-control > input:nth-child(1):checked ~ label:nth-of-type(1):before {
  opacity: 1;
}
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1):after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1):before,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1) ~ label:after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1) ~ label:before {
  left: 0;
}
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2):after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2):before,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2) ~ label:after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2) ~ label:before {
  left: 0;
}
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3):after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3):before,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:before {
  left: 0;
}
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4):after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4):before,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:before {
  left: 0;
}
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(5):after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(5):before,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  left: 0;
}
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(6):after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(6):before,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  left: 0;
}
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(7):after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(7):before,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:before {
  left: 0;
}
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(8):after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(8):before,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:before {
  left: 0;
}
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(9):after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(9):before,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:before {
  left: 0;
}
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(10):after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(10):before,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:after,
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:before {
  left: 0;
}


/* entry #2 */
.segmented-control > input:disabled:nth-child(2) ~ label:nth-of-type(2) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(2):checked ~ label:nth-of-type(2):after,
.segmented-control > input:nth-child(2):checked ~ label:nth-of-type(2):before {
  opacity: 1;
}
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2):after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2):before,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2) ~ label:after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2) ~ label:before {
  left: 50%;
}
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3):after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3):before,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:before {
  left: 33.33333%;
}
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4):after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4):before,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:before {
  left: 25%;
}
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(5):after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(5):before,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  left: 20%;
}
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(6):after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(6):before,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  left: 16.66%;
}
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(7):after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(7):before,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:before {
  left: 14.28%;
}
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(8):after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(8):before,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:before {
  left: 12.5%;
}
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(9):after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(9):before,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:before {
  left: 11.1111%;
}
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(10):after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(10):before,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:after,
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:before {
  left: 10%;
}

/* entry #3 */
.segmented-control > input:disabled:nth-child(3) ~ label:nth-of-type(3) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(3):checked ~ label:nth-of-type(3):after,
.segmented-control > input:nth-child(3):checked ~ label:nth-of-type(3):before {
  opacity: 1;
}
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3):after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3):before,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:before {
  left: 66.66667%;
}
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4):after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4):before,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:before {
  left: 50%;
}
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(5):after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(5):before,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  left: 40%;
}
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(6):after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(6):before,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  left: 33.3333%;
}
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(7):after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(7):before,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:before {
  left: 28.57%;
}
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(8):after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(8):before,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:before {
  left: 25%;
}
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(9):after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(9):before,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:before {
  left: 22.2222%;
}
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(10):after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(10):before,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:after,
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:before {
  left: 20%;
}

/* entry #4 */
.segmented-control > input:disabled:nth-child(4) ~ label:nth-of-type(4) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(4):checked ~ label:nth-of-type(4):after,
.segmented-control > input:nth-child(4):checked ~ label:nth-of-type(4):before {
  opacity: 1;
}
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4):after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4):before,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:before {
  left: 75%;
}
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(5):after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(5):before,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  left: 60%;
}
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(6):after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(6):before,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  left: 50%;
}
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(7):after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(7):before,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:before {
  left: 42.86%;
}
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(8):after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(8):before,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:before {
  left: 37.5%;
}
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(9):after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(9):before,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:before {
  left: 33.333%;
}
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(10):after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(10):before,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:after,
.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:before {
  left: 30%;
}

/* entry #5 */
.segmented-control > input:disabled:nth-child(5) ~ label:nth-of-type(5) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(5):checked ~ label:nth-of-type(5):after,
.segmented-control > input:nth-child(5):checked ~ label:nth-of-type(5):before {
  opacity: 1;
}
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(5):after,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(5):before,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  left: 80%;
}
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(6):after,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(6):before,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  left: 66.6667%;
}
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(7):after,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(7):before,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:after,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:before {
  left: 57.14%;
}
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(8):after,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(8):before,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:after,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:before {
  left: 50%;
}
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(9):after,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(9):before,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:after,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:before {
  left: 44.4444%;
}
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(10):after,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(10):before,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:after,
.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:before {
  left: 40%;
}

/* entry #6 */
.segmented-control > input:disabled:nth-child(6) ~ label:nth-of-type(6) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(6):checked ~ label:nth-of-type(6):after,
.segmented-control > input:nth-child(6):checked ~ label:nth-of-type(6):before {
  opacity: 1;
}
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(6):after,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(6):before,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  left: 83.3333%;
}
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(7):after,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(7):before,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:after,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:before {
  left: 71.43%;
}
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(8):after,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(8):before,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:after,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:before {
  left: 62.5%;
}
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(9):after,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(9):before,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:after,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:before {
  left: 55.56%;
}
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(10):after,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(10):before,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:after,
.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:before {
  left: 50%;
}

/* entry #7 */
.segmented-control > input:disabled:nth-child(7) ~ label:nth-of-type(7) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(7):checked ~ label:nth-of-type(7):after,
.segmented-control > input:nth-child(7):checked ~ label:nth-of-type(7):before {
  opacity: 1;
}
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(7):after,
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(7):before,
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:after,
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(7) ~ label:before {
  left: 85.71%;
}
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(8):after,
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(8):before,
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:after,
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:before {
  left: 75%;
}
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(9):after,
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(9):before,
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:after,
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:before {
  left: 66.6667%;
}
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(10):after,
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(10):before,
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:after,
.segmented-control > input:nth-child(7):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:before {
  left: 60%;
}

/* entry #8 */
.segmented-control > input:disabled:nth-child(8) ~ label:nth-of-type(8) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(8):checked ~ label:nth-of-type(8):after,
.segmented-control > input:nth-child(8):checked ~ label:nth-of-type(8):before {
  opacity: 1;
}
.segmented-control > input:nth-child(8):checked ~ label:first-of-type:nth-last-of-type(8):after,
.segmented-control > input:nth-child(8):checked ~ label:first-of-type:nth-last-of-type(8):before,
.segmented-control > input:nth-child(8):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:after,
.segmented-control > input:nth-child(8):checked ~ label:first-of-type:nth-last-of-type(8) ~ label:before {
  left: 87.5%;
}
.segmented-control > input:nth-child(8):checked ~ label:first-of-type:nth-last-of-type(9):after,
.segmented-control > input:nth-child(8):checked ~ label:first-of-type:nth-last-of-type(9):before,
.segmented-control > input:nth-child(8):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:after,
.segmented-control > input:nth-child(8):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:before {
  left: 77.7778%;
}
.segmented-control > input:nth-child(8):checked ~ label:first-of-type:nth-last-of-type(10):after,
.segmented-control > input:nth-child(8):checked ~ label:first-of-type:nth-last-of-type(10):before,
.segmented-control > input:nth-child(8):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:after,
.segmented-control > input:nth-child(8):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:before {
  left: 70%;
}

/* entry #9 */
.segmented-control > input:disabled:nth-child(9) ~ label:nth-of-type(9) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(9):checked ~ label:nth-of-type(9):after,
.segmented-control > input:nth-child(9):checked ~ label:nth-of-type(9):before {
  opacity: 1;
}
.segmented-control > input:nth-child(9):checked ~ label:first-of-type:nth-last-of-type(9):after,
.segmented-control > input:nth-child(9):checked ~ label:first-of-type:nth-last-of-type(9):before,
.segmented-control > input:nth-child(9):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:after,
.segmented-control > input:nth-child(9):checked ~ label:first-of-type:nth-last-of-type(9) ~ label:before {
  left: 88.8889%;
}
.segmented-control > input:nth-child(9):checked ~ label:first-of-type:nth-last-of-type(10):after,
.segmented-control > input:nth-child(9):checked ~ label:first-of-type:nth-last-of-type(10):before,
.segmented-control > input:nth-child(9):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:after,
.segmented-control > input:nth-child(9):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:before {
  left: 80%;
}

/* entry #10 */
.segmented-control > input:disabled:nth-child(10) ~ label:nth-of-type(10) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(10):checked ~ label:nth-of-type(10):after,
.segmented-control > input:nth-child(10):checked ~ label:nth-of-type(10):before {
  opacity: 1;
}
.segmented-control > input:nth-child(10):checked ~ label:first-of-type:nth-last-of-type(10):after,
.segmented-control > input:nth-child(10):checked ~ label:first-of-type:nth-last-of-type(10):before,
.segmented-control > input:nth-child(10):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:after,
.segmented-control > input:nth-child(10):checked ~ label:first-of-type:nth-last-of-type(10) ~ label:before {
  left: 90%;
}

/* this section controls the size of the options for each number of total options */
.segmented-control > label:first-of-type:nth-last-of-type(1),
.segmented-control > label:first-of-type:nth-last-of-type(1):after,
.segmented-control > label:first-of-type:nth-last-of-type(1):before,
.segmented-control > label:first-of-type:nth-last-of-type(1) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(1) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(1) ~ label:before {
  width: 100%;
}
.segmented-control > label:first-of-type:nth-last-of-type(2),
.segmented-control > label:first-of-type:nth-last-of-type(2):after,
.segmented-control > label:first-of-type:nth-last-of-type(2):before,
.segmented-control > label:first-of-type:nth-last-of-type(2) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(2) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(2) ~ label:before {
  width: 50%;
}
.segmented-control > label:first-of-type:nth-last-of-type(3),
.segmented-control > label:first-of-type:nth-last-of-type(3):after,
.segmented-control > label:first-of-type:nth-last-of-type(3):before,
.segmented-control > label:first-of-type:nth-last-of-type(3) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(3) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(3) ~ label:before {
  width: 33.33333%;
}
.segmented-control > label:first-of-type:nth-last-of-type(4),
.segmented-control > label:first-of-type:nth-last-of-type(4):after,
.segmented-control > label:first-of-type:nth-last-of-type(4):before,
.segmented-control > label:first-of-type:nth-last-of-type(4) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(4) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(4) ~ label:before {
  width: 25%;
}
.segmented-control > label:first-of-type:nth-last-of-type(5),
.segmented-control > label:first-of-type:nth-last-of-type(5):after,
.segmented-control > label:first-of-type:nth-last-of-type(5):before,
.segmented-control > label:first-of-type:nth-last-of-type(5) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(5) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(5) ~ label:before {
  width: 20%;
}
.segmented-control > label:first-of-type:nth-last-of-type(6),
.segmented-control > label:first-of-type:nth-last-of-type(6):after,
.segmented-control > label:first-of-type:nth-last-of-type(6):before,
.segmented-control > label:first-of-type:nth-last-of-type(6) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(6) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(6) ~ label:before {
  width: 16.6667%;
}
.segmented-control > label:first-of-type:nth-last-of-type(7),
.segmented-control > label:first-of-type:nth-last-of-type(7):after,
.segmented-control > label:first-of-type:nth-last-of-type(7):before,
.segmented-control > label:first-of-type:nth-last-of-type(7) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(7) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(7) ~ label:before {
  width: 14.2857%;
}
.segmented-control > label:first-of-type:nth-last-of-type(8),
.segmented-control > label:first-of-type:nth-last-of-type(8):after,
.segmented-control > label:first-of-type:nth-last-of-type(8):before,
.segmented-control > label:first-of-type:nth-last-of-type(8) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(8) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(8) ~ label:before {
  width: 12.5%;
}
.segmented-control > label:first-of-type:nth-last-of-type(9),
.segmented-control > label:first-of-type:nth-last-of-type(9):after,
.segmented-control > label:first-of-type:nth-last-of-type(9):before,
.segmented-control > label:first-of-type:nth-last-of-type(9) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(9) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(9) ~ label:before {
  width: 11.1111%;
}
.segmented-control > label:first-of-type:nth-last-of-type(10),
.segmented-control > label:first-of-type:nth-last-of-type(10):after,
.segmented-control > label:first-of-type:nth-last-of-type(10):before,
.segmented-control > label:first-of-type:nth-last-of-type(10) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(10) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(10) ~ label:before {
  width: 10%;
}

















.segmented-control > label {
  display: inline-block;
  padding: 0 0.71em;
  cursor: pointer;
  float: left;
  line-height: 3em;
  font-style: inherit;
  font-weight: 500;
  text-decoration: inherit;
     -moz-transition-property: none;
       -o-transition-property: none;
  -webkit-transition-property: none;
          transition-property: none;
     -moz-transition-duration: inherit;
       -o-transition-duration: inherit;
  -webkit-transition-duration: inherit;
          transition-duration: inherit;
     -moz-transition-timing-function: inherit;
       -o-transition-timing-function: inherit;
  -webkit-transition-timing-function: inherit;
          transition-timing-function: inherit;
}
.segmented-control > label:before,
.segmented-control > label:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.segmented-control > label:after {
  color: white;
  content: attr(data-value);
  text-align: center;
  padding: inherit;
  z-index: 10;
  font-style: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  opacity: 0;
     -moz-transition-property: opacity, left;
       -o-transition-property: opacity, left;
  -webkit-transition-property: opacity, left;
          transition-property: opacity, left;
     -moz-transition-duration: inherit;
       -o-transition-duration: inherit;
  -webkit-transition-duration: inherit;
          transition-duration: inherit;
     -moz-transition-timing-function: inherit;
       -o-transition-timing-function: inherit;
  -webkit-transition-timing-function: inherit;
          transition-timing-function: inherit;
}
.segmented-control > label:after,
.segmented-control > label {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
     -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.segmented-control > label:before {
  content: "";
  color: inherit;
     -moz-transition-property: left;
       -o-transition-property: left;
  -webkit-transition-property: left;
          transition-property: left;
     -moz-transition-duration: inherit;
       -o-transition-duration: inherit;
  -webkit-transition-duration: inherit;
          transition-duration: inherit;
     -moz-transition-timing-function: inherit;
       -o-transition-timing-function: inherit;
  -webkit-transition-timing-function: inherit;
          transition-timing-function: inherit;
}

.segmented-control.italic,
.segmented-control .italic {
  font-style: italic;
}
.segmented-control.bold,
.segmented-control .bold {
  font-weight: bold;
}
.segmented-control.underline,
.segmented-control .underline {
  text-decoration: underline;
}
.segmented-control.line-through,
.segmented-control .line-through {
  text-decoration: line-through;
}
